<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <span class="brand-logo">
          <img
            src="@/assets/images/logo/erflog.png"
            alt="logo"
          />
        </span> -->
         <img src="@/assets/images/erflog/logo.png" alt="">
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-0 mobile_bg_main"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            src="@/assets/images/erflog/login.jpg"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-3"
      >
        <b-col
          sm="8"
          md="8"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Forgot Your Password? 
          </b-card-title>
          <b-card-text class="mb-2 bold_text">
            Please enter the email address you'd like your password reset information sent to.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                @click.prevent="sendForgottePassLink()"
              >
                <span v-if="!isLogging">
                  Request Reset Link
                </span>
                <span v-else>
                  <b-spinner small/>
                </span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}" class="bold-text">
              <feather-icon icon="ChevronLeftIcon--" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//FIREBASE 
import firebase from "firebase";
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import { dbCollections } from '@/utils/firebaseCollection.js';
export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      isLogging:false,
      allUser:[]
    }
  },
  watch: {
    $route: function () {
      this.init();          
    }
  },
  created(){
    this.init();
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
      init(){
      //GET USER STORE COLLECTION DATA
      getQuery.getDataFromRootCollection(dbCollections.USERS,(res)=>{
        this.allUser = [];
        if(res && res.length > 0){         
          res.forEach(element => {
            if(element.isDeleted !== true){
              this.allUser.push(element)              
            }
          });
        }
      })
    },   
    sendForgottePassLink(){
      this.isLogging = true
     this.$refs.simpleRules.validate().then(success => {
      if (success) {
        if(this.allUser.length > 0){
          let emailDataInd = this.allUser.findIndex(item=>{
            return item.email == this.userEmail || item.personalEmail == this.userEmail || item.companyEmail == this.userEmail;
          }) 
          if(emailDataInd > -1){
          let userData = this.allUser[emailDataInd]; 
          console.log(userData,"emailDataInd");
          var actionCodeSettings = {
              url: window.location.origin,
          };     
          firebase
          .auth()
          .sendPasswordResetEmail(this.userEmail, actionCodeSettings)
          .then(()=>
          {        
            this.isLogging=false
            //  localStorage.setItem('UpdatePasswordUSER',JSON.stringify(userData)) 
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Email sent successfully.',
                  icon: 'onedrivenew',
                  variant: 'success',
                },
              })
            })
            .catch(error=>{
              this.isLogging=false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error}`,
                  icon: 'onedrivenew',
                  variant: 'danger',
                },
              })
            })                        
          }else{
            this.isLogging=false
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Email not exist.',
                  icon: 'onedrivenew',
                  variant: 'danger',
                },
              })
          }
        }
        else
        {
          this.isLogging=false
        }
      }
      else
      {
        this.isLogging=false
      }
     })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
